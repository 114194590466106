import React, {FC, HTMLAttributes} from 'react';
// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';
import PropTypes from "prop-types";
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import {Loader, ThemeProvider, Theme, Alert} from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import awsexports from '../aws-exports';
import useMinimizeAside from "../hooks/useMinimizeAside";

Amplify.configure(awsexports);

const dictionary = {
	'es': {
		waitingCameraPermissionText: "Esperando permitas el acceso a tu cámara...",
		goodFitCaptionText: 'Buen ajuste',
		tooFarCaptionText: 'Demasiado lejos',
		hintCenterFaceText: 'Centra tu rostro',
		hintTooCloseText: 'Aléjate',
		hintTooFarText: 'Acércate',
		hintConnectingText: 'Empezando...',
		hintVerifyingText: 'Verificando...',
		hintCheckCompleteText: 'Validación Facial Completa',
		hintHoldFaceForFreshnessText: 'No te muevas',
		startScreenBeginCheckText: 'Iniciar Verificación',
		timeoutHeaderText: 'Tiempo de Verificación Terminado',
		tryAgainText: 'Intentarlo Nuevamente',
		timeoutMessageText: "No encontramos tu rostro dentro del óvalo en el límite de tiempo. Inténtalo nuevamente colocando tu rostro en el óvalo.",
		serverHeaderText: 'Servicio no disponible',
		serverMessageText: 'No se logró completar la validación, intente nuevamente',
		cameraNotFoundHeadingText: 'La cámara no es accesible',
		cameraNotFoundMessageText: 'Compruebe que haya una cámara conectada y que no haya otra aplicación utilizando la cámara. Es posible que tengas que ingresar a la configuración para otorgar permisos a la cámara, cerrar todas las instancias de tu navegador y volver a intentarlo.',
		retryCameraPermissionsText: 'Intentarlo Nuevamente',
		hintMoveFaceFrontOfCameraText: 'Mueve tu rostro frente a la cámara',
	},
};

interface ILivenessProps extends HTMLAttributes<HTMLDivElement> {
	primaryColor: any;
	secondaryColor: any;
	dataLiveness: any;
}
const Liveness: FC<ILivenessProps> = ({
  primaryColor,
  secondaryColor,
  dataLiveness
}) => {

	useMinimizeAside();
	const [loading, setLoading] = React.useState(true);
	const [createLivenessApiData, setCreateLivenessApiData] = React.useState({
		sessionId: ""
	});
	const theme: Theme = {
		name: 'Face Liveness Ebia Theme',
		tokens: {
			colors: {
				font: {
					primary: { value: '#000' },
					secondary: { value: primaryColor },
					tertiary: { value: secondaryColor },
				},
				background: {
					primary: { value: '#FFF' },
					warning: { value: '#FFF' },
				},
				border: {
					primary: { value: primaryColor },
					secondary: { value: primaryColor },
					tertiary: { value: primaryColor },
				},
				primary: {
					"10": primaryColor,
					"20": primaryColor,
					"40": primaryColor,
					"60": primaryColor,
					"80": primaryColor,
					"90": primaryColor,
					"100": primaryColor
				}
			},
			components: {
				button: {
					// style the primary variation
					primary: {
						backgroundColor: { value: secondaryColor },
						_hover: {
							backgroundColor: { value: primaryColor },
						},
						_focus: {
							backgroundColor: { value: primaryColor },
						},
						_active: {
							backgroundColor: { value: primaryColor },
						}
					},
				},
			},
		},
	};

	React.useEffect(() => {
		fetchCreateLiveness();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchCreateLiveness = async () => {
		setLoading(true);
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ })
		};
		const response = await fetch(`${process.env.REACT_APP_URL_BACKEND}/reconocimiento/createSession`, requestOptions);
		const jsonResponse = await response.json();
		setCreateLivenessApiData(jsonResponse);
		console.log(createLivenessApiData.sessionId)
		setLoading(false);
	};

	const handleAnalysisComplete = async () => {
		/*
         * This should be replaced with a real call to your own backend API
         */
		if (createLivenessApiData) {
			const response = await fetch(`${process.env.REACT_APP_URL_BACKEND}/reconocimiento/getSessionResults?sessionId=${createLivenessApiData.sessionId}`);
			const data = await response.json();

			dataLiveness(data.response)
		}
	};

	return (
		<div className='row'>
			<div className='col-12 mb-3'>
				<ThemeProvider theme={theme} colorMode='system'>
					{loading || createLivenessApiData.sessionId==="" ? (
						<Loader />
					) : (
						<FaceLivenessDetector
							sessionId={createLivenessApiData.sessionId}
							region="us-east-1"
							displayText={dictionary.es}
							onAnalysisComplete={handleAnalysisComplete}
							onUserCancel={fetchCreateLiveness}
							disableStartScreen
							components={
								{
									// eslint-disable-next-line react/no-unstable-nested-components
									PhotosensitiveWarning: (): JSX.Element => {
										return (
											// eslint-disable-next-line react/jsx-no-useless-fragment
											<></>
										);
									}
								}
							}
						/>
					)}
				</ThemeProvider>
			</div>
		</div>
	);

};
Liveness.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	primaryColor: PropTypes.any,
	// eslint-disable-next-line react/forbid-prop-types
	secondaryColor: PropTypes.any
};
Liveness.defaultProps = {
	primaryColor: '#78be21',
	secondaryColor: '#375737'
};

export default Liveness;
