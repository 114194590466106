import { useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

export const VideoPlayer = (props: any) => {
    const videoRef = useRef<any>(null);
    const playerRef = useRef<any>(null);
    const { options, onReady } = props;

    useEffect(() => {
        if (!playerRef.current) {
            const videoElement = document.createElement("video-js");

            videoElement.classList.add("vjs-big-play-centered");
            videoRef.current.appendChild(videoElement);

            const player = videojs(videoElement, options, () => {
                onReady && onReady(player);
            });
        } else {
            const player = playerRef.current;

            player.autoplay(options.autoplay);
            player.src(options.sources);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <div data-vjs-player>
            {/* eslint-disable-next-line react/react-in-jsx-scope */}
            <div ref={videoRef} />
        </div>
    );
};

export default VideoPlayer;