import React from 'react';
import { RouteProps } from 'react-router-dom';
import { dashboardPagesMenu, externalPages, pageLayoutTypesPagesMenu } from '../menu';
import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';
import DefaultHeader from '../pages/_layout/_headers/DefaultHeader';

const headers: RouteProps[] = [
	{ path: externalPages.login.path, element: null },
	{ path: externalPages.signUp.path, element: null },
	{ path: externalPages.page404.path, element: null },
	{ path: externalPages.externalSign.path, element: null },
	{ path: dashboardPagesMenu.dashboard.path, element: <DashboardHeader /> },
	{
		path: `*`,
		element: <DefaultHeader />,
	},
];

export default headers;
