import React, { useContext } from 'react';
import Header, {HeaderLeft, HeaderRight} from '../../../layout/Header/Header';
import Popovers from '../../../components/bootstrap/Popovers';
import Button, { IButtonProps } from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import Icon from '../../../components/icon/Icon';
import ThemeContext from '../../../contexts/themeContext';

const DefaultHeader = () => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const styledBtn: IButtonProps = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	return (
		<Header>

			<HeaderLeft>
				<div className='row g-3 align-items-center'>Hi</div>
			</HeaderLeft>

			<HeaderRight>
				<div className='row g-3 align-items-center'>
					<div className='col-auto'>
						<Popovers trigger='hover' desc='Light / Dark'>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								onClick={() => setDarkModeStatus(!darkModeStatus)}
								className='btn-only-icon'
								data-tour='dark-mode'
								aria-label='Toggle dark mode'>
								<Icon
									icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
									color={darkModeStatus ? 'info' : 'warning'}
									className='btn-icon'
								/>
							</Button>
						</Popovers>
					</div>
					{/*	Full Screen */}
					<div className='col-auto'>
						<Popovers trigger='hover' desc='Pantalla Completa'>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
								onClick={() => setFullScreenStatus(!fullScreenStatus)}
								aria-label='Toggle fullscreen'
							/>
						</Popovers>
					</div>
				</div>
			</HeaderRight>

		</Header>
	);
};

export default DefaultHeader;
