export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: 'dashboard',
		icon: 'Dashboard',
		subMenu: null,
	},
};

export const externalPages = {
	auth: {
		id: 'auth',
		text: 'Auth Pages',
		icon: 'Extension',
	},
	login: {
		id: 'login',
		text: 'Login',
		path: 'auth-pages/login',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'auth-pages/sign-up',
		icon: 'PersonAdd',
	},
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth-pages/404',
		icon: 'ReportGmailerrorred',
	},
	externalSign: {
		id: 'externalSign',
		text: 'Sign',
		path: 'link/sign',
		icon: 'check_box_outline_blank',
	},
};

export const pageLayoutTypesPagesMenu = {
	layoutTypes: {
		id: 'layoutTypes',
		text: 'Page Layout Types',
	},
	pageLayout: {
		id: 'pageLayout',
		text: 'Page Layout',
		path: 'app',
		icon: 'BackupTable',
		subMenu: {
			headerAndSubheader: {
				id: 'headerAndSubheader',
				text: 'Header & Subheader',
				path: 'app/header-and-subheader',
				icon: 'ViewAgenda',
			},
			onlyHeader: {
				id: 'onlyHeader',
				text: 'Only Header',
				path: 'app/only-header',
				icon: 'ViewStream',
			},
			onlySubheader: {
				id: 'onlySubheader',
				text: 'Only Subheader',
				path: 'app/only-subheader',
				icon: 'ViewStream',
			},
			onlyContent: {
				id: 'onlyContent',
				text: 'Only Content',
				path: 'app/only-content',
				icon: 'WebAsset',
			},
		},
	},
	asideTypes: {
		id: 'asideTypes',
		text: 'Aside Types',
		path: 'aside-types',
		icon: 'Vertical Split',
		subMenu: {
			defaultAside: {
				id: 'defaultAside',
				text: 'Default Aside',
				path: 'aside-types/default-aside',
				icon: 'ViewQuilt',
			},
			minimizeAside: {
				id: 'minimizeAside',
				text: 'Minimize Aside',
				path: 'aside-types/minimize-aside',
				icon: 'View Compact',
			},
		},
	},
};

export const ebiaPageLayoutTypesPagesMenu = {
	layoutTypes: {
		id: 'titleLayoutTypes',
		text: 'Services',
	},
	signLayout: {
		id: 'signLayout',
		text: 'Sign',
		path: 'sign',
		icon: 'Create',
		subMenu: {
			homeSign: {
				id: 'homeSign',
				text: 'Home',
				path: 'app/sign/home',
				icon: 'OtherHouses',
			},
			workflowSign: {
				id: 'workflowSign',
				text: 'Workflow',
				path: 'app/sign/workflow',
				icon: 'WrapText',
			},
			oneShotSign: {
				id: 'oneShotSign',
				text: 'One Shot',
				path: 'app/sign/oneshot',
				icon: 'PlusOne',
			},
		},
	},
	signatureLayout: {
		id: 'signaturesLayout',
		text: 'Signatures',
		path: 'signature',
		icon: 'Vertical Split',
		subMenu: {
			homeSignature: {
				id: 'homeSignature',
				text: 'Home',
				path: 'app/signature/home',
				icon: 'HomeWork',
			},
		},
	},
};