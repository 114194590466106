import React, { FC, useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Buffer } from 'buffer';
import Webcam from "react-webcam";
import Liveness from "../../../components/Liveness";
import fingerprintImg from '../../../assets/img/fingerprint_code.png';
import Progress from '../../../components/bootstrap/Progress';
import COLORS from '../../../common/data/colorDummyData';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import Spinner from '../../../components/bootstrap/Spinner';
import showNotification from '../../../components/extras/showNotification';
import Carousel from '../../../components/bootstrap/Carousel';
import CarouselSlide from '../../../components/bootstrap/CarouselSlide';
import Icon from '../../../components/icon/Icon';
import Checks from '../../../components/bootstrap/forms/Checks';
import Alert from '../../../components/bootstrap/Alert';
import Video from "../../../components/VideoPlayer";
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';

const videoConstraints = {
	width: 600,
	height: 600,
	facingMode: "environment"
};

interface IExternalSignProps {
	isExternalSign?: boolean;
}
const ExternalSign: FC<IExternalSignProps> = ({ isExternalSign }) => {

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [urlValid,setUrlValid] = useState<boolean>(true);
	const [searchParams] = useSearchParams();
	const [params] = React.useState(searchParams.get("p"));
	const [paramsJson,setParamsJson] = useState({
		"token": "",
		"idCompany": 0,
		"idRekognition": 0,
		"rc": true,
		"fingerprint": "",
		"idCompanyProcess": 0,
		"identificationSignatory": "",
		"phoneSignatory": "",
		"order": 0
	})
	const [documents,setDocuments] = useState<string>("");
	const [otpValue,setOtpValue] = useState<string>("");
	const [signatory,setSignatory] = useState({
		"identificacion": "",
		"nombres": "",
		"apellido_paterno": "",
		"apellido_materno": "",
		"provincia": "",
		"ciudad": "",
		"direccion": "",
		"email": "",
		"telefono": "",
		"motivo_documento": "",
		"cedula_frontal": "",
		"cedula_posterior": "",
		"firmante": ""
	})
	const [company,setCompany] = useState({
		"ID_EMPRESA":  "",
		"IDENTIFICACION_EMPRESA": "",
		"RAZON_SOCIAL_EMPRESA": "",
		"RAO": "",
		"AUTENTICACION": "",
		"OTP": "",
		"AMBIENTE": "",
		"TOKEN": "",
		"BILLING_USERNAME": "",
		"PASSWORD_USERNAME": "",
		"LOGO_URL_EMPRESA": "",
		"COLOR_EMPRESA": "",
		"COLOR_ADICIONAL_EMPRESA": "",
		"TEXTO_ACEPTACION_EMPRESA": "",
		"CONSOLIDADOR_EMPRESA": "",
		"CONSOLIDADOR_TENANT_EMPRESA": "",
		"GET_ID_CARD_ONBOARDING_EMPRESA": false,
		"TERMINOS_CONDICIONES_EMPRESA": ""
	})
	const [step,setStep] = useState(0);
	const [acceptTerms,setAcceptTerms] = useState<boolean>(false);
	const [location,setLocation] = useState<string>("");
	const [agentDevice,setAgentDevice] = useState<string>("");
	const [messageProcess,setMessageProcess] = useState<string>("false");
	const refresh = () => window.location.reload();
	const [liveness,setLiveness] = useState<boolean>(false);
	const [verifiedIdentity, setverifiedIdentity] = useState<boolean>(false);
	const [countProgress, setCountProgress] = useState(0);
	const [externalSignStatus, setExternalSignStatus] = useState<boolean>(!!isExternalSign);
	const webcamRef = React.useRef<Webcam>(null);
	const [state, setState] = useState(false);
	const [centeredStatus, setCenteredStatus] = useState(false);
	const [headerCloseStatus, setHeaderCloseStatus] = useState(true);

	const initialStatus = () => {
		setCenteredStatus(false);
		setHeaderCloseStatus(true);
	};

	useEffect(() => {
		if(params) {
			try {
				const json = JSON.parse(atob(params));
				setParamsJson(json);
			} catch (error) {
				setUrlValid(false);
			}
		}
	},[params]);

	useEffect(() => {
		let count = 0;
		const countInterval = setInterval(() => {
			count += 1;
			setCountProgress(count);
			if(count===100) {
				clearInterval(countInterval);
			}
		}, 800);
	}, [externalSignStatus]);

	const validateSchemaOtp = Yup.object().shape({
		otp: Yup.string()
			.required("Ingrese el OTP recibido en su whatsapp y/o email")
			.min(4, 'El OTP debe tener 4 digitos')
			.max(4, 'El OTP debe tener 4 digitos')
			.matches(/^[0-9]+$/, 'Ingrese solo números')
	});

	const validateSchemaFingerprint = Yup.object().shape({
		fingerprint: Yup.string()
			.required("Ingrese el código de huella dactilar")
			.min(6, 'El código de huella debe tener 6 caracteres')
			.max(6, 'El código de huella debe tener 6 acracteres')
			.matches(/^[A-Z]\d\d\d\d[A-Z]$/, "Debe contener 1 letra MAYÚSCULA, 4 numeros y 1 letra MAYÚSCULA")
	});

	const formikOtp = useFormik({
		enableReinitialize: true,
		initialValues: {
			otp: "",
		},
		validationSchema: validateSchemaOtp,
		validateOnChange: false,
		onSubmit: (values) => {
			validateCode(values.otp);
			setIsLoading(true);
			setTimeout(() => {
				setIsLoading(false);
			}, 1000 * 2);
		}
	});

	const formikFingerprint = useFormik({
		enableReinitialize: true,
		initialValues: {
			fingerprint: "",
		},
		validationSchema: validateSchemaFingerprint,
		validateOnChange: false,
		onSubmit: (values) => {
			facialVerification(values.fingerprint);
			setIsLoading(true);
			setTimeout(() => {
				setIsLoading(false);
			}, 1000 * 2);
		}
	});

	// VERIFY CODE OTP
	const validateCode = async (otp: string) => {
		try {
			const optionGetDocuments = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${paramsJson.token}`
				},
				body: JSON.stringify({
					id_empresa: paramsJson.idCompany,
					solicitud_firmante: paramsJson.idCompanyProcess,
					identificacion_firmante: paramsJson.identificationSignatory
				})
			}
			const responseGetDocuments = await fetch(`${process.env.REACT_APP_URL_BACKEND}/api/one-shot/obtener/documentos`, optionGetDocuments)
			const jsonResponseGetDocuments = await responseGetDocuments.json();
			if(jsonResponseGetDocuments.result){
				setDocuments(jsonResponseGetDocuments.documentos);
				const optionVerifyCode = {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${paramsJson.token}`
					},
					body: JSON.stringify({
						documentos: jsonResponseGetDocuments.documentos,
						codigo: otp,
						ep: paramsJson.phoneSignatory,
						orden: paramsJson.order,
						id_rekognition: paramsJson.idRekognition
					})
				};
				const responseVerifyCode = await fetch(`${process.env.REACT_APP_URL_BACKEND}/contrato/verificar/codigo/one-shot`, optionVerifyCode);
				const jsonResponseVerifyCode = await responseVerifyCode.json();

				if(jsonResponseVerifyCode.result){
					// SET OTP VALUE
					setOtpValue(otp)
					const f = jsonResponseVerifyCode.firmante;
					const flow = params && JSON.parse(atob(params)).flow && JSON.parse(atob(params)).flow === 2 ? 'STANDARD' : f.CONSOLIDADOR_EMPRESA;
					/* SET DATA SIGNATORY */
					setSignatory(previousState => {return { ...previousState, identificacion: f.IDENTIFICACION_FIRMANTE }});
					setSignatory(previousState => {return { ...previousState, nombres: f.NOMBRES_FIRMANTE }});
					setSignatory(previousState => {return { ...previousState, apellido_paterno: f.APELLIDO_PATERNO_FIRMANTE }});
					setSignatory(previousState => {return { ...previousState, apellido_materno: f.APELLIDO_MATERNO_FIRMANTE }});
					setSignatory(previousState => {return { ...previousState, provincia: f.PROVINCIA_FIRMANTE }});
					setSignatory(previousState => {return { ...previousState, ciudad: f.CIUDAD_FIRMANTE }});
					setSignatory(previousState => {return { ...previousState, direccion: f.DIRECCION_FIRMANTE }});
					setSignatory(previousState => {return { ...previousState, email: f.EMAIL_FIRMANTE }});
					setSignatory(previousState => {return { ...previousState, telefono: f.TELEFONO_FIRMANTE }});
					setSignatory(previousState => {return { ...previousState, motivo_documento: f.MOTIVO_DOCUMENTO }});
					setSignatory(previousState => {return { ...previousState, cedula_frontal: f.PATH_CEDULA_FRONTAL_FIRMANTE }});
					setSignatory(previousState => {return { ...previousState, cedula_posterior: f.PATH_CEDULA_POSTERIOR_FIRMANTE }});
					/* SET DATA COMPANY */
					setCompany(previousState => {return { ...previousState, ID_EMPRESA:  f.ID_EMPRESA }});
					setCompany(previousState => {return { ...previousState, IDENTIFICACION_EMPRESA: f.IDENTIFICACION_EMPRESA }});
					setCompany(previousState => {return { ...previousState, RAZON_SOCIAL_EMPRESA: f.RAZON_SOCIAL_EMPRESA }});
					setCompany(previousState => {return { ...previousState, RAO: f.RAO_UANATACA_EMPRESA }});
					setCompany(previousState => {return { ...previousState, AUTENTICACION: f.TIPO_AUTENTICACION_EXTERNA }});
					setCompany(previousState => {return { ...previousState, OTP: f.OTP_EMPRESA }});
					setCompany(previousState => {return { ...previousState, AMBIENTE: f.AMBIENTE_EMISION_EMPRESA }});
					setCompany(previousState => {return { ...previousState, TOKEN: f.TOKEN_EMPRESA }});
					setCompany(previousState => {return { ...previousState, BILLING_USERNAME: f.BILLING_USERNAME_EMPRESA }});
					setCompany(previousState => {return { ...previousState, PASSWORD_USERNAME: f.BILLING_PASSWORD_EMPRESA }});
					setCompany(previousState => {return { ...previousState, COLOR_EMPRESA: f.COLOR_EMPRESA }});
					setCompany(previousState => {return { ...previousState, COLOR_ADICIONAL_EMPRESA: f.COLOR_ADICIONAL_EMPRESA }});
					setCompany(previousState => {return { ...previousState, TEXTO_ACEPTACION_EMPRESA: f.TEXTO_ACEPTACION_EMPRESA }});
					setCompany(previousState => {return { ...previousState, CONSOLIDADOR_EMPRESA: flow }});
					setCompany(previousState => {return { ...previousState, CONSOLIDADOR_TENANT_EMPRESA: f.CONSOLIDADOR_TENANT_EMPRESA }});
					setCompany(previousState => {return { ...previousState, GET_ID_CARD_ONBOARDING_EMPRESA: f.GET_ID_CARD_ONBOARDING_EMPRESA }});
					setCompany(previousState => {return { ...previousState, TERMINOS_CONDICIONES_EMPRESA: f.TERMINOS_CONDICIONES_EMPRESA }});
					setStep(1);
				} else{
					showNotification(
						'Algo anda mal...',
						jsonResponseVerifyCode.message,
						'warning' // 'default' || 'info' || 'warning' || 'success' || 'danger',
					);
					// REGISTER ATTEMPT
					registerAttempt();
				}
			} else{
				jsonResponseGetDocuments.error==='jwt expired' ?
					showNotification(
						'Link Caducado',
						'Solicite el envio de un nuevo link',
						'default' // 'default' || 'info' || 'warning' || 'success' || 'danger',
					)
				:
					showNotification(
						'Documentos Requeridos',
						jsonResponseGetDocuments.message,
						'info' // 'default' || 'info' || 'warning' || 'success' || 'danger',
					)
			}
		} catch (error) {
			showNotification(
				'Servicio no disponible',
				'Espera unos minutos y vuelve a intentarlo',
				'info' // 'default' || 'info' || 'warning' || 'success' || 'danger',
			);
		}
	};

	// REGISTER ATTEMPT
	const registerAttempt = async () => {
		const optionRegisterAttempt = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${paramsJson.token}`
			},
			body: JSON.stringify({
				documentos: documents
			})
		};
		await fetch(`${process.env.REACT_APP_URL_BACKEND}/contrato/registrar/intento`, optionRegisterAttempt);
	};

	// ACCEPT THE TERMS AND CONDITIONS
	const handleChangeAcceptTerms = async () => {
		if(paramsJson.rc!==undefined && !paramsJson.rc || company.GET_ID_CARD_ONBOARDING_EMPRESA)
			setStep(5);
		else
			setStep(6);
		// GET LOCATION AND AGENT
		 navigator.geolocation.getCurrentPosition(function(position) {
			const localizacion = `${position.coords.latitude},${position.coords.longitude}`;
			setLocation(localizacion);
			setAgentDevice(navigator.userAgent);
		});
	}

	// LIFE TEST ANALYSIS
	const analyzeDataLiveness = async (data: any) => {
		const status = data.Status;
		const confidence = data.Confidence;
		const captureData = data.ReferenceImage.Bytes.data;
		const captureBuffer = Buffer.from(captureData);
		const captureBlob = new Blob([captureBuffer], { type: "image/jpeg" });
		// UPLOAD CAPTURE S3
		const dataCapture = await uploadCaptureS3(captureBlob,'CAPTURE');
		const optionAnalyzeCapture = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${paramsJson.token}`
			},
			body: JSON.stringify({
				key: dataCapture.key
			})
		};
		const responseAnalyzeCapture = await fetch(`${process.env.REACT_APP_URL_BACKEND}/reconocimiento/analizar/captura`, optionAnalyzeCapture);
		const jsonAnalyzeCapture = await responseAnalyzeCapture.json();
		/* console.log(jsonAnalyzeCapture) */
		if(jsonAnalyzeCapture.result){
			setSignatory(previousState => {return { ...previousState, firmante: dataCapture.location }});
			if (status==="SUCCEEDED" && confidence>=15) {
				setLiveness(true);
				if(paramsJson.rc && paramsJson.fingerprint==="")
					setStep(3);
				else
					facialVerification(paramsJson.fingerprint,dataCapture.location);
			} else {
				setStep(3);
				// REGISTER ATTEMPT
				registerAttempt();
				setMessageProcess("Suplantación de Identidad, no puedes usar videos o imagenes en la validación biométrica");
			}
		} else {
			setStep(3);
			setMessageProcess(jsonAnalyzeCapture.message);
		}
	}

	// UPLOAD CAPTURE S3
	const uploadCaptureS3 = async (capture: any, type: string) => {
		const hoy = Date.now();
		const data:any = new FormData();
		data.append('lado', type);
		data.append('cedula', signatory.identificacion);
		data.append('id', otpValue);
		data.append('file', capture, `${hoy}.jpg`);
		const optionUploadCaptureS3 = {
			method: 'POST',
			body: data
		};
		const responseUploadCaptureS3 = await fetch(`${process.env.REACT_APP_URL_BACKEND}/reconocimiento/cargar/captura`, optionUploadCaptureS3);
		const jsonRegisterAttempt = await responseUploadCaptureS3.json();
		return jsonRegisterAttempt;
	}

	// FACIAL VERIFICATION
	const facialVerification = async (fingerprintValue: string, capture?: string) => {
		showNotification(
			'Verificación Facial',
			'Estamos verificando tu identidad...',
			'info' // 'default' || 'info' || 'warning' || 'success' || 'danger',
		);
		let responseFacialVerification = null;
		let jsonResponseFacialVerification = null;
		let optionfacialVerification = null;
		if(paramsJson.rc) {
			optionfacialVerification = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${paramsJson.token}`
				},
				body: JSON.stringify({
					id_empresa: company.ID_EMPRESA,
					url_verificacion: signatory.firmante==="" ? capture : signatory.firmante,
					identificacion: signatory.identificacion,
					huella: fingerprintValue,
					nombres: (signatory.nombres).trim(),
					apellidos: `${(signatory.apellido_paterno).trim()} ${(signatory.apellido_materno).trim()}`
				})
			}
			responseFacialVerification = await fetch(`${process.env.REACT_APP_URL_BACKEND}/api/rekognition/comparar/rostro/rc`, optionfacialVerification)
			jsonResponseFacialVerification = await responseFacialVerification.json();
		} else {
			optionfacialVerification = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${paramsJson.token}`
				},
				body: JSON.stringify({
					id_empresa: company.ID_EMPRESA,
					url_base: signatory.cedula_frontal,
					url_verificacion: capture
				})
			}
			responseFacialVerification = await fetch(`${process.env.REACT_APP_URL_BACKEND}/api/rekognition/comparar/rostro`, optionfacialVerification)
			jsonResponseFacialVerification = await responseFacialVerification.json();
		}
		/* console.log(jsonResponseFacialVerification) */
		if(jsonResponseFacialVerification.result){
			setStep(4);
			const similarity = paramsJson.rc ? 98 : 90;
			if(jsonResponseFacialVerification.FaceMatches[0] && jsonResponseFacialVerification.FaceMatches[0].Similarity>=similarity){
				setCountProgress(0);
				setverifiedIdentity(true);
				setExternalSignStatus(true);
				const rekognitionBase64 = btoa(JSON.stringify(jsonResponseFacialVerification.FaceMatches[0]));
				setMessageProcess("Tus documentos se están firmando no cierres esta ventana, espera unos segundos...");
				const valuesExternalSign = {
					codigo: otpValue,
					documentos: JSON.parse(atob(documents)),
					motivo_documento: signatory.motivo_documento,
					user: company,
					identificacion: signatory.identificacion,
					nombres: signatory.nombres,
					apellido_paterno: signatory.apellido_paterno,
					apellido_materno: signatory.apellido_materno,
					firmante: signatory.firmante==="" ? capture : signatory.firmante,
					rekognition: rekognitionBase64,
					email: signatory.email,
					ep: signatory.telefono,
					provincia: signatory.provincia,
					ciudad: signatory.ciudad,
					direccion: signatory.direccion,
					cedula_frontal: signatory.cedula_frontal,
					cedula_posterior: signatory.cedula_posterior,
					orden: paramsJson.order,
					agent: agentDevice,
					localizacion: location,
					id_request: 0,
					rc: paramsJson.rc,
					rf: 2,
					id_rekognition: paramsJson.idRekognition,
					id_solicitud: paramsJson.idCompanyProcess
				};
				/* console.log(valuesExternalSign) */
				// REGISTER REKOGNITION
				const optionRegisterRekognition = {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${paramsJson.token}`
					},
					body: JSON.stringify(valuesExternalSign)
				};
				await fetch(`${process.env.REACT_APP_URL_BACKEND}/api/one-shot/registrar/rekognitionGenerico`, optionRegisterRekognition);
				if(company.CONSOLIDADOR_EMPRESA==='ONBOARDING_INDEPENDIENTE'){
					setCountProgress(100);
					setMessageProcess("Perfecto, tu identidad ha sido verificada correctamente. Agradecemos por tu tiempo");
				} else{
					// SEND SIGNATURE REQUEST
					const optionSendSignatureRequest = {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
							'Authorization': `Bearer ${paramsJson.token}`
						},
						body: JSON.stringify(valuesExternalSign)
					};
					const responseSendSignatureRequest = await fetch(`${process.env.REACT_APP_URL_BACKEND}/api/one-shot/generico/enviar`, optionSendSignatureRequest);
					const jsonResponseSendSignatureRequest = await responseSendSignatureRequest.json();
					/* console.log(jsonResponseSendSignatureRequest) */
					// SET VALUE ID REQUEST id_request
					const idRequest = jsonResponseSendSignatureRequest.id_request;
					valuesExternalSign.id_request = idRequest;
					// FINISH SIGNING PROCESS
					setTimeout(async () => {
						const optionFinishSigningProcess = {
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
								'Authorization': `Bearer ${paramsJson.token}`
							},
							body: JSON.stringify(valuesExternalSign)
						};
						const responseFinishSigningProcess = await fetch(`${process.env.REACT_APP_URL_BACKEND}/api/one-shot/finalizar/firma/documento/certificado`, optionFinishSigningProcess);
						const jsonResponseFinishSigningProcess = await responseFinishSigningProcess.json();
						/* console.log(jsonResponseFinishSigningProcess) */
						setCountProgress(100);
						if (jsonResponseFinishSigningProcess.result)
							setMessageProcess("Perfecto, los documentos se firmaron electrónicamente. En pocos minutos recibirás un correo con una copia de los documentos.");
						else
							setMessageProcess(jsonResponseFinishSigningProcess.message)
					}, 1000 * 20);
				}
			} else {
				setMessageProcess("Identidad No Verificada, no estas autorizad@ para firmar los documentos");
				// REGISTER ATTEMPT
				registerAttempt();
				// REGISTER FAILED STATUS
				registerFailedStatus();
				// REPORT CUSTOMER ERROR
				reportCustomerError("Identidad No Verificada, no estas autorizad@ para firmar los documentos");
			}
		} else {
			showNotification(
				'Verificación Registro Civil',
				jsonResponseFacialVerification.message,
				'warning' // 'default' || 'info' || 'warning' || 'success' || 'danger',
			);
			// eslint-disable-next-line prefer-destructuring
			let message = jsonResponseFacialVerification.message;
			if(jsonResponseFacialVerification.code===9){
				message = "Suplantación de identidad, no estas autorizad@ para firmar los documentos";
				// REGISTER ATTEMPT
				registerAttempt();
				// REGISTER FAILED STATUS
				registerFailedStatus();
			}
			setMessageProcess(message);
			// REPORT CUSTOMER ERROR
			reportCustomerError(message);
			if(paramsJson.fingerprint!=="" && (jsonResponseFacialVerification.code===5 || jsonResponseFacialVerification.code===10))
				setStep(3);
			else
				setStep(4);
		}
	}

	// REGISTER FAILED STATUS
	const registerFailedStatus = async () => {
		const optionRegisterFailedStatus = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${paramsJson.token}`
			},
			body: JSON.stringify({
				documentos: JSON.parse(atob(documents))
			})
		};
		await fetch(`${process.env.REACT_APP_URL_BACKEND}/contrato/registrar/estado/documento`, optionRegisterFailedStatus);
	};

	// REPORT CUSTOMER ERROR
	const reportCustomerError = async (message: string) => {
		const optionReportCustomerError = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${paramsJson.token}`
			},
			body: JSON.stringify({
				id_rekognition: paramsJson.idRekognition,
				ruc: company.IDENTIFICACION_EMPRESA,
				consolidador: company.CONSOLIDADOR_TENANT_EMPRESA,
				mensaje: message
			})
		};
		await fetch(`${process.env.REACT_APP_URL_BACKEND}/api/reportar/vf`, optionReportCustomerError);
	};

	// CAPTURE ID
	const captureId = async () => {
		setIsLoading(true);
		const imageSrc = webcamRef.current?.getScreenshot();
		if(imageSrc) {
			const captureBlob = dataURItoBlob(imageSrc);
			// UPLOAD CAPTURE S3
			const type = signatory.cedula_frontal===null || signatory.cedula_frontal==="" ? 'FRONTAL' : 'POSTERIOR';
			const dataCapture = await uploadCaptureS3(captureBlob, type);
			// ID VERIFICATION
			const optionVerificationId = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${paramsJson.token}`
				},
				body: JSON.stringify({
					key: dataCapture.key,
					type,
					cedula: signatory.identificacion,
					nombres: signatory.nombres,
					apellido_paterno: signatory.apellido_paterno,
					apellido_materno: signatory.apellido_materno,
					ruc: company.IDENTIFICACION_EMPRESA
				})
			}
			const responseVerificationId = await fetch(`${process.env.REACT_APP_URL_BACKEND}/reconocimiento/verificar/cedula`, optionVerificationId)
			const jsonResponseVerificationId = await responseVerificationId.json();
			/* console.log(jsonResponseVerificationId) */
			setTimeout(() => {
				setIsLoading(false);
			}, 1000 * 3);
			if(jsonResponseVerificationId.result){
				if(type==='FRONTAL') {
					setSignatory(previousState => {return {...previousState, cedula_frontal: dataCapture.location}});
					showNotification(
						`Verificación cédula ${type.toLowerCase()}`,
						"Cédula frontal capturada correctamente",
						'success' // 'default' || 'info' || 'warning' || 'success' || 'danger',
					);
				}
				if(type==='POSTERIOR') {
					setSignatory(previousState => {return {...previousState, cedula_posterior: dataCapture.location}});
					setStep(6);
				}
			} else
				showNotification(
					`Verificación cédula ${type.toLowerCase()}`,
					jsonResponseVerificationId.message,
					'warning' // 'default' || 'info' || 'warning' || 'success' || 'danger',
				);
		}
	}

	// STRING BASE64 TO BLOB
	function dataURItoBlob(dataURI: string) {
		const byteString = atob(dataURI.split(',')[1]);
		const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
		const ab = new ArrayBuffer(byteString.length);
		const ia = new Uint8Array(ab);
		for (let i = 0; i < byteString.length; i += 1) {
			ia[i] = byteString.charCodeAt(i);
		}
		const blob = new Blob([ab], {type: mimeString});
		return blob;
	}

	return (
		<PageWrapper
			isProtected={false}
			title='Sign'
			className={classNames({ 'bg-dark': true })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								{
									params && /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/.test(params) && JSON.parse(atob(params)).logo && JSON.parse(atob(params)).logo!=="" ?
										<div className='text-center my-sm-auto'>
											<div className='col-12'>
												<img src={ JSON.parse(atob(params)).logo } alt='logo' width='30%' />
											</div>
										</div>
										:
										<div className='text-center my-sm-auto'>
											<Logo width={step===0 ? 150 : 100} />
										</div>
								}
								{
									(() => {
										switch(step) {

											case(0): {
												return (
													<div>
														{urlValid ? (
															<>
																<div className='text-center h1 fw-bold mt-5'>Bienvenido,</div>
																<div className='text-center h3 fw-bold mt-5'>Firma Electrónica de Documentos</div>
																<div className='text-center h4 text-muted mb-5'>Revisa tu whatsapp o email</div>
																<form className='row g-4'>
																	<div>
																		<div className='col-12'>
																			<FormGroup
																				id='otp'
																				isFloating
																				label='Ingresar Código'>
																				<Input
																					type="number"
																					max={9999}
																					value={formikOtp.values.otp}
																					isTouched={formikOtp.touched.otp}
																					invalidFeedback={
																						formikOtp.errors.otp
																					}
																					isValid={formikOtp.isValid}
																					onChange={formikOtp.handleChange}
																					onBlur={formikOtp.handleBlur}
																					onFocus={() => {
																						formikOtp.setErrors({});
																					}}
																					onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
																						if (e.target.value.length > e.target.maxLength)
																							e.target.value = e.target.value.slice(0, 4);
																					}}
																					onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
																				/>
																			</FormGroup>
																		</div>
																		<div className='col-12 mt-4'>
																			<Button
																				color='dark'
																				className='w-100 py-3'
																				isDisable={!formikOtp.values.otp || isLoading}
																				onClick={formikOtp.handleSubmit}>
																				{isLoading && (
																					<Spinner isSmall inButton isGrow/>
																				)}
																				Ingresar
																			</Button>
																		</div>
																	</div>
																</form>
																{
																	params && /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/.test(params) && JSON.parse(atob(params)).video && JSON.parse(atob(params)).video !== "" &&
																		<div className='col-12 mt-4'>
																			<Button
																				color='dark'
																				className='w-100 py-3'
																				isLight
																				icon='OndemandVideo'
																				onClick={() => {
																					initialStatus();
																					setCenteredStatus(true);
																					setState(true);
																				}}>
																				Video Instructivo
																			</Button>
																		</div>
																}
															</>
														) : (
															<div className='text-center h4 text-muted mb-5'>Url incorrecta, cierra esta ventana y presiona nuevamente el link desde tu whastapp o email</div>
														)}
													</div>
												)
											}

											case(1): {
												return (
													<>
														<div className='text-center h4 fw-bold mt-3'>Documentos</div>
													 	<div className='row'>
															<div className='align-items-center col-xxl-12 col-xl-12'>
																<Card className={classNames('shadow-3d-info', 'mb-4', 'bg-l25-info')}>
																	<Carousel
																		isHoverPause
																		isRide
																		isDark>
																		{(JSON.parse(atob(documents))).map((i: any, index: any) => (
																			<a key={i.ID_DOCUMENTO} aria-label="document" href={i.PATH_DOCUMENTO} target='_blank' rel='noopener noreferrer'>
																				<CarouselSlide>
																					<div className='row g-4 align-items-center'>
																						<div className='col-xl-12'>
																							<div
																								className={classNames(
																									'd-flex align-items-center rounded-2 p-3', 'bg-l10-warning',
																								)}>
																								<div className='flex-shrink-0'>
																									<Icon
																										icon='PictureAsPdf'
																										size='4x'
																										color='danger'
																									/>
																								</div>
																								<div className='flex-grow-1 ms-3'>
																									<div className='fw-bold text-muted mt-n2'>{i.NOMBRE_DOCUMENTO.length<=40 ? i.NOMBRE_DOCUMENTO : `${i.NOMBRE_DOCUMENTO.substring(0, 15)}..........${(i.NOMBRE_DOCUMENTO.substring(i.NOMBRE_DOCUMENTO.length - 15, i.NOMBRE_DOCUMENTO.length))}`}</div>
																									<div className='text-muted mt-1'>{index+1} de {(JSON.parse(atob(documents))).length}</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</CarouselSlide>
																			</a>
																		))}
																	</Carousel>
																</Card>
															</div>
														</div>
														<Alert color='dark' isOutline>
															<div className='text-center h6 text-muted'>Revisa los documentos y confirma tus datos personales, si estás de acuerdo acéptalos marcando la casilla en la parte inferior</div>
														</Alert>
														<div className='row'>
															<Card className='shadow-3d-info'>
																<CardBody>
																	<div className='align-items-center col-xxl-12 col-xl-12'>
																		<div className='col-12'>
																			<div className='d-flex align-items-center'>
																				<div className='flex-shrink-0'>
																					<Icon icon='PermIdentity' size='3x' color='info' />
																				</div>
																				<div className='flex-grow-1 ms-3'>
																					<div className='fw-bold fs-5 mb-0'>
																						{signatory.nombres} {signatory.apellido_paterno} {signatory.apellido_materno}
																					</div>
																					<div className='text-muted'>
																						Nombres
																					</div>
																				</div>
																			</div>
																		</div>
																		<div className='col-12'>
																			<div className='d-flex align-items-center'>
																				<div className='flex-shrink-0'>
																					<Icon icon='Tag' size='3x' color='info' />
																				</div>
																				<div className='flex-grow-1 ms-3'>
																					<div className='fw-bold fs-5 mb-0'>
																						{signatory.identificacion}
																					</div>
																					<div className='text-muted'>
																						Cédula
																					</div>
																				</div>
																			</div>
																		</div>
																		<div className='row g-4 mt-auto'>
																			<p className='fw-normal'>
																				{ company.TEXTO_ACEPTACION_EMPRESA !== null && (company.TEXTO_ACEPTACION_EMPRESA).replace('MOTIVO_DOCUMENTO',signatory.motivo_documento).split('SALTO_LINEA')[0] ? (company.TEXTO_ACEPTACION_EMPRESA).replace('MOTIVO_DOCUMENTO',signatory.motivo_documento).split('SALTO_LINEA')[0] : `Acepto se acceda al uso de mis datos personales protegidos por la "Ley de Protección de Datos Personales" contemplado en el el artículo 66, numeral 19 de la Constitución de la República del Ecuador. El uso de mis datos tiene como propósito la firma electrónica de los documentos indicados con la razón de firmado `}
																				{ company.TEXTO_ACEPTACION_EMPRESA === null &&
																					<b>{signatory.motivo_documento}.</b>
																				}
																				<br /><br />
																				{ company.TEXTO_ACEPTACION_EMPRESA !== null &&(company.TEXTO_ACEPTACION_EMPRESA).replace('MOTIVO_DOCUMENTO',signatory.motivo_documento).split('SALTO_LINEA')[1] && (company.TEXTO_ACEPTACION_EMPRESA).replace('MOTIVO_DOCUMENTO',signatory.motivo_documento).split('SALTO_LINEA')[1]}
																				<br />
																				{ company.TERMINOS_CONDICIONES_EMPRESA !== null &&
																					<a href={company.TERMINOS_CONDICIONES_EMPRESA} target='_blank' className='text-decoration-none'>Leer Política de privacidad</a>
																				}
																			</p>
																			<Button
																				color='dark'
																				className='w-100 py-3'
																				isDisable={isLoading}
																				onClick={handleChangeAcceptTerms}>
																				{isLoading && (
																					<Spinner isSmall inButton isGrow/>
																				)}
																				Aceptar y Continuar
																			</Button>
																		</div>
																	</div>
																</CardBody>
															</Card>
														</div>
													</>
												)
											}

											case(2): {
												return (
													<>
														<div className='text-center h4 fw-bold mt-3'>Verificación de Identidad</div>
														<div className='row'>
															<div className='align-items-center col-xxl-12 col-xl-12'>
																<Liveness
																	primaryColor={company.COLOR_EMPRESA ?? '#78be21'}
																	secondaryColor={company.COLOR_ADICIONAL_EMPRESA ?? '#375737'}
																	dataLiveness={analyzeDataLiveness}
																/>
															</div>
														</div>
													</>
												)
											}

											case(3): {
												return (
													<>
														<div className='text-center h4 fw-bold mt-3'>Verificación de Identidad</div>
														{
															liveness ?
																<>
																	<div className='row text-center'>
																		<Alert color='dark' isOutline>
																			Ingresa los 6 primeros caracteres del codigo dactilar que se encuentra en el lado posterior de tu cédula
																		</Alert>
																		<div className='col-12'>
																			<img src={fingerprintImg} width='100%' alt='' />
																		</div>
																	</div>
																	<form className='row g-4'>
																		<div className='col-12'>
																			<FormGroup
																				id='fingerprint'
																				isFloating
																				label='Ingresar Huella Dactilar'>
																				<Input
																					value={formikFingerprint.values.fingerprint}
																					isTouched={formikFingerprint.touched.fingerprint}
																					invalidFeedback={
																						formikFingerprint.errors.fingerprint
																					}
																					isValid={formikFingerprint.isValid}
																					onChange={formikFingerprint.handleChange}
																					onBlur={formikFingerprint.handleBlur}
																					onFocus={() => {
																						formikFingerprint.setErrors({});
																					}}
																					onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
																						if (e.target.value.length > e.target.maxLength)
																							e.target.value = e.target.value.slice(0, 6);
																					}}
																					onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
																				/>
																			</FormGroup>
																		</div>
																		<div className='col-12'>
																			<Button
																				color='warning'
																				className='w-100 py-3'
																				isDisable={!formikFingerprint.values.fingerprint}
																				onClick={formikFingerprint.handleSubmit}>
																				{isLoading && (
																					<Spinner isSmall inButton isGrow/>
																				)}
																				Ingresar
																			</Button>
																		</div>
																	</form>
																</>
																:
																<>
																	<div className='row text-center'>
																		<Alert color='dark' isOutline>
																			{messageProcess}
																		</Alert>
																	</div>
																	<div className='row text-center'>
																		<div className='col-12'>
																			<Button
																				color='dark'
																				className='w-100 py-3'
																				onClick={refresh}>
																				{isLoading && (
																					<Spinner isSmall inButton isGrow/>
																				)}
																				Salir
																			</Button>
																		</div>
																	</div>
																</>
														}
													</>
												)
											}

											case(4): {
												return (
													<>
														<div className='text-center h4 fw-bold mt-3'>{ company.CONSOLIDADOR_EMPRESA==='ONBOARDING_INDEPENDIENTE' ? 'Verificación de Identidad' : 'Firma de Documentos'}</div>
														<div className='row text-center'>
															<Alert color={verifiedIdentity ? 'success' : 'danger'} isOutline>
																{messageProcess}
															</Alert>
															{
																verifiedIdentity && countProgress!==100 && company.CONSOLIDADOR_EMPRESA!=='ONBOARDING_INDEPENDIENTE' ?
																	<div className='col-12'>
																		<div className='row'>
																			<div key='PRIMARY' className='col-12'>
																				<Progress value={countProgress} isAnimated color={COLORS.PRIMARY} />
																			</div>
																		</div>
																	</div>
																	:
																	null
															}
														</div>
														{
															!verifiedIdentity || countProgress===100 || company.CONSOLIDADOR_EMPRESA==='ONBOARDING_INDEPENDIENTE' &&
																<div className='row text-center'>
																	<div className='col-12'>
																		<Button
																			color='dark'
																			className='w-100 py-3'
																			onClick={refresh}>
																			{isLoading && (
																				<Spinner isSmall inButton isGrow/>
																			)}
																			Salir
																		</Button>
																	</div>
																</div>
														}
													</>
												)
											}

											case(5): {
												return (
													<>
														<div className='text-center h4 fw-bold mt-3'>Cédula {signatory.cedula_frontal===null || signatory.cedula_frontal==="" ? 'Frontal' : 'Posterior'}</div>
														<div className='text-center h6 text-muted'>Se requiere que tomes la cédula física en tu mano</div>
														<div className='row'>
															<div className='align-items-center col-xxl-12 col-xl-12'>
																<Webcam
																	audio={false}
																	ref={webcamRef}
																	screenshotFormat="image/jpeg"
																	videoConstraints={videoConstraints}
																	height="100%"
																	width="100%"
																	className="rounded-5"
																	screenshotQuality={1}
																/>
															</div>
															<div className='col-12 mt-4'>
																<Button
																	color='dark'
																	className='w-100 py-3'
																	isDisable={isLoading}
																	onClick={captureId}>
																	{isLoading && (
																		<Spinner isSmall inButton isGrow/>
																	)}
																	Capturar Cédula {signatory.cedula_frontal===null || signatory.cedula_frontal==="" ? 'Frontal' : 'Posterior'}
																</Button>
															</div>
														</div>
													</>
												)
											}

											case(6): {
												return (
													<>
														<div className='text-center h4 fw-bold mt-3'>Verificación de Identidad</div>
														<div className='row text-center'>
															<p className='fs-5'>
																Retírate los accesorios como gafas, lentes, gorros, sombreros, capuchas, etc... Para empezar recuerda que no puedes estar acompañado y debes mantener tu rostro centrado frente a la cámara.<br/>
																Estas listo?
															</p>
															<Button
																color='dark'
																className='w-100 py-3'
																isDisable={isLoading}
																onClick={() => setStep(2)}>
																{isLoading && (
																	<Spinner isSmall inButton isGrow/>
																)}
																Empecemos
															</Button>
														</div>
													</>
												)
											}

											default: {
												return (
													<div className='text-center h4 text-muted mb-5'>Unknown Step</div>
												)
											}
										}
									})()
								}
							</CardBody>
						</Card>
					</div>
				</div>
				<Modal
					isOpen={state}
					setIsOpen={setState}
					titleId='videoModal'
					isCentered={centeredStatus}>
					<ModalHeader setIsOpen={headerCloseStatus ? setState : undefined}>
						<ModalTitle id='videoModal'>Video Instructivo</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<div className='align-items-center col-xxl-12 col-xl-12'>
							{
								params && /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/.test(params) && JSON.parse(atob(params)).video && JSON.parse(atob(params)).video !== "" &&
									<Video options={
										{
											controls: true,
											responsive: true,
											fluid: true,
											experimentalSvgIcons: true,
											sources: [
												{
													src: JSON.parse(atob(params)).video,
													type: "video/mp4"
												}
											]
										}
									} />
							}
						</div>
					</ModalBody>
					<ModalFooter>
						<Button
							color='dark'
							isOutline
							className='border-0'
							onClick={() => setState(false)}>
							Cerrar
						</Button>
					</ModalFooter>
				</Modal>
			</Page>
		</PageWrapper>
	);
};
ExternalSign.propTypes = {
	isExternalSign: PropTypes.bool,
};
ExternalSign.defaultProps = {
	isExternalSign: false,
};

export default ExternalSign;
