import React, { lazy } from 'react';
import { dashboardPagesMenu, externalPages, ebiaPageLayoutTypesPagesMenu } from '../menu';
import Login from '../pages/ebia/auth/Login';
import ExternalSign from '../pages/ebia/link/ExternalSign';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/ebia/dashboard/DashboardPage')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/ebia/auth/Page404')),
};
const PAGE_LAYOUTS = {
	/** SIGN */
	PRINCIPAL_SIGN: lazy(() => import('../pages/ebia/app/sign/Home')),
	WORKFLOW_SIGN: lazy(() => import('../pages/ebia/app/sign/Workflow')),
	ONE_SHOT_SIGN: lazy(() => import('../pages/ebia/app/sign/Oneshot')),
	/** SIGN */
	ADMIN_SIGNATURE: lazy(() => import('../pages/ebia/app/signature/Home')),
};

const pages = [
	/**
	 * Landing
	 */
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	{
		path: externalPages.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: externalPages.login.path,
		element: <Login />,
	},
	{
		path: externalPages.signUp.path,
		element: <Login isSignUp />,
	},
	{
		path: externalPages.externalSign.path,
		element: <ExternalSign />,
	},

	/** ************************************************** */

	/**
	 * Page Layout Types
	 */
	{
		path: ebiaPageLayoutTypesPagesMenu.signLayout.subMenu.homeSign.path,
		element: <PAGE_LAYOUTS.PRINCIPAL_SIGN />,
	},
	{
		path: ebiaPageLayoutTypesPagesMenu.signLayout.subMenu.workflowSign.path,
		element: <PAGE_LAYOUTS.WORKFLOW_SIGN />,
	},
	{
		path: ebiaPageLayoutTypesPagesMenu.signLayout.subMenu.oneShotSign.path,
		element: <PAGE_LAYOUTS.ONE_SHOT_SIGN />,
	},
	{
		path: ebiaPageLayoutTypesPagesMenu.signatureLayout.subMenu.homeSignature.path,
		element: <PAGE_LAYOUTS.ADMIN_SIGNATURE />,
	},
];
const contents = [...pages];

export default contents;
